.Navigator {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 2em;
    font-size: 2em;
    background-color: rgba(232, 218, 146, 0.4);
    font-family: 'Noto Serif SC', serif;
    font-weight: 500;
    backdrop-filter: blur(10px);
    transition: linear 0.2s;
    border-bottom: 1px solid rgba(186, 186, 186, 0.4);
    z-index: 999;
}

.Navigator a.Selected{
    font-weight: 500;
}

.Navigator:hover {
    background-color: rgba(232, 218, 146, 0.8);
    backdrop-filter: blur(10px);
}

.Logo {
    float: left;
    font-family: "Noto Serif SC", serif;
    font-weight: 100;
    height: 100%;
    margin-left: .4em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Menu {
    position: relative;
    right: 1rem;
    display: flex;
    justify-content: right;
    align-items: center;
    flex-direction: row;
    height: 100%;
}

.Navigator a {
    text-decoration: none;
    color: black;
    margin: 0 0.4em;
    width: 7em;
    text-align: center;
    font-weight: 200;
}

.Navigator:hover a {
    font-weight: 700;
}

.Navigator a:hover {
    background-color: rgba(200, 200, 200, 0.39)
}

.Background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    bottom: 0;
    background: url("/public/background.jpg");
    z-index: -1;
    background-size: cover;
    opacity: 0.35;
}

@media(max-width: 900px) {
    .Navigator {
        background-color: transparent;
        position: static;
        top: 0;
        backdrop-filter: none;
        font-family: sans-serif;
        height: auto;
        font-size: 1.2em;
        padding: 0.5em;
    }
    .Navigator a {
        text-align: left;
        width: auto;
    }
    .Navigator:hover {
        background-color: transparent;
    }
    .Logo {
        display: none;
    }
    .Background {
        display: none;
    }
}
