:root {
    --border-default-width: 1px;
}

.LoginWindow {
    margin: 0 auto;
    padding: 10px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    border-radius: 6px;
    border: var(--border-default-width) solid rgba(186, 186, 186, 0.4);
    text-align: center;
    width: 35em;
    transition: linear 0.2s;
    backdrop-filter: blur(10px);
}

.LoginWindow:hover {
    box-shadow: rgba(105, 105, 105, 0.37) 0 0 9px;
    border: var(--border-default-width) solid rgb(186, 186, 186);
}

.LoginWindow h3 {
    margin-block-start: 0.4em;
    margin-block-end: 0.4em;
    font-size: 1.6em;
}

.LoginWindow p {
    margin-block-start: 0.4em;
    margin-block-end: 0.4em;
}

.LoginWindow input {
    margin-left: 1em;
    height: 1.5em;
    width: 13em;
    border-radius: 15px;
    border: var(--border-default-width) solid rgb(186, 186, 186);
    padding-left: 0.8em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 100;
    font-size: 0.8em;
    transition: linear 0.2s;
}

.LoginWindow input:hover{
    box-shadow: rgba(105, 105, 105, 0.37) 0 0 6px;
    border: var(--border-default-width) solid black;
}

.LoginWindow input:focus{
    width: 16em;
}

.LoginWindow .SubmitButton {
    border: var(--border-default-width) solid rgba(186, 186, 186, 0.4);
    border-radius: 1.2rem;
    height: 2.4rem;
    width: 6rem;
    background-color: rgb(0, 128, 255);
    color: white;
    font-size: 1.2em;
    font-family: 'Noto Serif SC', serif;
    font-weight: bold;
    cursor: pointer;
    transition: linear 0.2s;
}

.LoginWindow .SubmitButton:hover {
    background-color: rgb(0, 67, 136);
    box-shadow: rgba(105, 105, 105, 0.37) 0 0 6px;
    width: 9rem;
}

.LoginWindow .SwitchButton {
    border: none;
    border-radius: 1.2rem;
    height: 2.4rem;
    width: 6rem;
    background: lightgrey;
    font-size: 1.2em;
    font-family: 'Noto Serif SC', serif;
    font-weight: bold;
    cursor: pointer;
    transition: linear 0.2s;
}

.LoginWindow .SwitchButton:hover {
    box-shadow: rgba(105, 105, 105, 0.37) 0 0 6px;
    width: 9rem;
}

.LoginWindow .Tips {
    font-size: 0.9em;
}

.LoginWindow .Tips span {
    color: #00a8c7;
}

.LoginWindow .Tips span:hover {
    text-shadow: rgba(105, 105, 105, 0.37) 0 0 2px;
}

.LoginWindow .Detail {
    font-size: 0.5em;
}

.LoginWindow .TypeOptions {
    background: rgba(100,100,100,0.2);
    width: 60%;
    margin: 0 auto;
    padding: 0.2em;
    font-size: 0.8em;
    border-radius: 1em;
}

.LoginWindow .TypeOptions .TypeOption:hover {
    font-weight: bold;
    cursor: pointer;
}

.LoginWindow font {
    font-size: 0.8em;
}

.LoginWindow .KeyInput {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    width: 80%;
    margin: 0.5em auto;
    flex-wrap: wrap;
}

.LoginWindow .KeyInput span {
    width: 5rem;
    text-align: right;
}

@media(max-width: 900px) {
    .LoginWindow {
        width: 90%;
    }
    :root {
        --border-default-width: 2px;
    }
    .LoginWindow .TipsWhenMobile {
        font-size: 0.9em;
    }
    .LoginWindow .TypeOptions {
        background: none;
        width: 100%;
        font-weight: 100;
        font-size: 0.6em;
    }
}

@media(max-width: 42em) {
    .LoginWindow .KeyInput {
        width: 90%;
    }
    .LoginWindow .KeyInput span {
        display: none;
    }
    .LoginWindow input {
        margin-left: 0;
    }
}