.Article {
    margin: 5em auto;
    width: min(75em, 90%);
    font-family: 'Noto Serif SC', serif;
}

.Article h1 {
    text-align: center;
    font-size: 3em;
}

.Article p {
    font-size: 1.2em;
    font-weight: 450;
}

.Article h2 {
    font-size: 1.8em;
}

.Article a {
    color: rgb(0, 128, 255);
}

@media(max-width: 900px) {
    .Article {
        font-family: sans-serif;
        margin: 0 auto;
    }
    .Article p {
        line-height: 1.6em;
        font-weight: 350;
    }
    .Article h1 {
        text-align: left;
        font-size: 1.8em;
    }
    .Article h2 {
        font-size: 1.5em;
    }
}